import React from 'react';


const Collect: React.FC = () => {
    return (
        <>
            <div className="container">
                <h1> This is Collect page</h1>

            </div>
        </>
    );
};

export default Collect;